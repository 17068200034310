import "./collapsible.scss"
import { CollapsibleItem } from "../collapsibleitem/collapsibleitem"

export const Collapsible = (props) => {
    const { data, title } = props

    return (
        <div className={"collapse-container"}>
            <p>{title}</p>
            {data.map((value, index) => {
                return <CollapsibleItem key={index} data={value} open={index===0?true:false}> </CollapsibleItem>
            })}
        </div>
    )
}