
import {axiosClient} from '../api';

export async function fetchMediumPublication() {
    return await axiosClient.get(`/feed`)
        .then((response) => {
            return response.data;
        })
        .catch(error => {
            throw error
        })
}