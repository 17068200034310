
import { Cloudoverview } from "../../constants"
import data from "../../assets/data.svg"
import analytics from "../../assets/analytics.svg"
import response from "../../assets/response.svg"
import { Singlepage } from "../../components/services/singlepage"
export const Cloud = () => {
    const images = [data, analytics, response]

    return (

        <Singlepage
            images={images}
            title="Managed Cloud Security Operations Service"
            subtext={`Today, data is the most attractive resource to cyber attackers. 
                      Traditionally, organizations have mostly built their security around their network infrastructure.
                      
                      However, with several new entry points arising from the ubiquity of computing, it is important to 
                      provide an all round approach to securing the cloud infrastructure. Security in the cloud involves 
                      the protection of all resources without leaving any gaps. Secure wildcard fits in the shared 
                      responsibility model for security operations in the cloud. We will function to monitor, detect and 
                      respond to threats in your cloud environment, while ensuring that you are maintaining a culture of 
                      security best practices in your cloud environment.


                      Secure wildcard fits in the shared responsiblility model for security operations in the cloud.
                      We will function to monitor, detect and respond to threats in your cloud environment, while
                      ensuring that you are maintaining a culture of security best practise in your cloud environment. <br/>
                      <br/>
                      <strong> Detect </strong> <br/>
                      <br/>
                      Cloud service providers (CSP) provide different tools to support security operations in the cloud.
                      We will assist you in monitoring these tools, triage, investigate alerts and respond to them accordingly. 
                      Working with us mean that you no longer need to worry about the a combination of alerts generated by different
                      tools on a daily basis and we will support you in getting the best out of your CSP. <br/>
                      <br/>
                      <strong>Hunt</strong> <br/>
                      <br/>
                      In the event of a breach, we are able to participate and assist you in hunting for
                      threats in your cloud environment. We will combine our cyber security
                      experience with the log sources and tools provided by your cloud service 
                      provider to investigate threats such as malicious emails, compromised identities, 
                      data access, data exfiltration, malware including ransomware, RCEs and so on. <br/>
                      <br/>
                      <strong> Respond </strong> <br/>
                      <br/>
                      Through established standard operating procedures and agreed responsibility matrix, 
                      we will provide adequate support to inform you in the event that an adversary has attempted 
                      to or successfully compromised your environment. We will provide you with adequate 
                      remediation steps as well as automate response to suspicious or confirmed incidents when and 
                      where necessary. <br/>
                      `}
            approach="Our approach."
            overview={`We all agree that monitoring the cloud infrastructure can be a dauting task 
            for cloud sec ops team. If you have a dedicated security operations team, 
            this could be a less uphill task, however, due to the shortage of cyber security professionals, 
            many companies do not have the resources to reach fully capabilities for security operations in the cloud. 
            
            We offer security operations support for the cloud services related to:`}

            soverview={Cloudoverview}
        />
    )
}