import { Switch, Route } from 'react-router';
import { Home } from '../../../pages/home/home';
import { Servicespage } from '../../../pages/services/services';
import { Error } from '../../../pages/errors/errors';
import { About } from '../../../pages/about/about';
import { Contact } from '../../../pages/contact/contact';
import { Navbar } from '../../../components/navbar/navbar';
import { Footer } from '../../../components/footer/footer';
import { Edr } from '../../../pages/edr/edr';
import { Siem } from '../../../pages/siem/siem';
import { Cloud } from '../../../pages/cloud/cloud';
import './App.scss';

const App = () => {
  
  return (
    <div className="App">
      <Navbar />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/services" component={Servicespage} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/contact-us" component={Contact} />
        <Route exact path="/error" component={Error} />
        <Route exact path="/services/edr" component={Edr} />
        <Route exact path="/services/siem" component={Siem} />
        <Route exact path="/services/cloud" component={Cloud} />
        <Route component={Error} />
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
