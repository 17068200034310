import "./values.scss"
import security from "../../assets/security.svg"
import credible from "../../assets/credible.svg"
import visible from "../../assets/visible.svg"


export const Values = () => {
    const data = [
        { title: "Security", image: security, content: "We strongly believe active security activities and dectective controls is a vital part of enabling business to perform seamlessly. By partnering with us, you can be assured that the security of your business is in good hands." },
        { title: 'Transparency', image: visible, content: "It is important for us to provide concise and consistent progress. Our goal is to always be opened in communication and accountable in activities. We keep you in the loop and provide you adequate reporting at all times." },
        { title: 'Support', image: credible, content: "At SecureWildCard, we will support you with all security services, tools and recommendations needed to protect your BUSINESS against both internal and external cyber threats. We are available 24/7/365." }
    ]
    return (
        <div className="values-container">
            <div className="values-inner-container">
                {
                    data.map((item, index) => (
                        <Singlevalue
                            key={index}
                            title={item.title}
                            image={item.image}
                            content={item.content}
                        />
                    ))
                }

            </div>
        </div>
    )
}

const Singlevalue = (props) => {

    return (
        <div className="single-value">
            <img src={props.image} alt=" value svg " />
            <div className="bottom-content">
                <p>
                    {props.title}
                </p>
                <p>
                    {props.content}
                </p>
            </div>
        </div>
    )
}