import "./servicespage.scss";
import { Banner } from "../../components/banner/banner"
import { ExtendedService } from "../../components/extended-services/extended-services"
import {ConsultationService } from "../../components/consultation/consultation"
import banner1 from "../../assets/Secure-1.svg"

export const Servicespage = () => {
    const description = "We focus on providing cybersecurity operation services to both private and public sector customers. Founded in 2021, our team delivers top-notch security services like deployment and integration of cybersecurity tools, training and certification, as well as strategy and consulting services."
    
    return (
        <div className="servicespage-container">
            <Banner title={"What we Do"} banner={banner1} description={description} />
            <ExtendedService />
            <ConsultationService/>
        </div>
    )
}