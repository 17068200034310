import "./singlenews.scss"


export const Singlenews = (props) => {
    const {title, url, image} = props

    return (
        <div className="singlenews-container">
            <div className="singlenews-inner-container">
                <div className="singlenews-inner-container top">
                    <img src={image} alt="News image"/>
                </div>
                <div className="singlenews-inner-container bottom">
                    <div className="bottom content">
                        <p>{title.replace('&amp;', '&')}</p>
                    </div>
                    <a href={url} target={"_blank"} rel="noreferrer">Read More</a>
                </div>

            </div>
        </div>
    )
}