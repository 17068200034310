import "./home.scss"
import { Banner } from "../../components/banner/banner"
import { Tools } from "../../components/tools/tools"
import { Service } from "../../components/services/services"
import { Product } from "../../components/product/product"
import { Explore } from "../../components/explore/explore"
import { Help } from "../../components/help/help"
import { serviceDescriptionData } from "../../interfaces/interfaces.tsx"


export const Home = () => {

    const data = serviceDescriptionData

    return (
        <div className="home-container">
            {/* <Navbar/> */}
            <Banner/>
            <Service data={data} service_title={"Our Services"} />
            <Tools/>
            <Product/>
            <Help/>
            <Explore/>
        </div>
    )
}