import "./explore.scss"
import { useEffect, useState } from "react"
import left from "../../assets/left.svg"
import right from "../../assets/right.svg"
import { Singlenews } from "./singlenews"
import { fetchMediumPublication } from "../../requests/requests"

export const Explore = () => {
    const [posts, setBlogPosts] = useState([]);

    const [curIndex, setcurIndex] = useState([0])

    useEffect(() => {
        fetchMediumPublication()
            .then(data => {
                setBlogPosts(data.items)
            })
    }, [])

    if (posts.length === 0) {
        return <div></div>
    }

    const shiftLeft = () => {
        if (allowLeft()) {
            setcurIndex(curIndex - 1)
        }
    }

    const shiftRight = () => {
        if (allowRight()) {
            setcurIndex(curIndex + 1)
        }
    }

    const renderLeftClaret = () => {
        if (allowLeft()) {
            return <div className="chevron-left" onClick={shiftLeft}>
                <img src={left} alt="Chevron Left" />
            </div>
        }

        return <div className="chevron-left hide">
            <img src={left} alt="Chevron Left" />
        </div>
    }

    const renderRightClaret = () => {
        if (allowRight()) {
            return <div className="chevron-right" onClick={shiftRight}>
                <img src={right} alt="Chevron Right" />
            </div>
        }

        return <div className="chevron-right hide">
            <img src={right} alt="Chevron Right" />
        </div>
    }

    const allowLeft = () => {
        return curIndex > 0
    }

    const allowRight = () => {
        return curIndex + 3 < posts.length
    }

    return (
        <div className="explore-container">
            <div className="explore-inner-container">
                <p>Blog Post</p>
                <div className="explore-inner-container bottom">
                    {renderLeftClaret()}
                    {
                        posts.slice(curIndex, curIndex + 3).map((value, key) => {
                            return <Singlenews key={key} title={value.title} url={value.link} image={value.thumbnail}/>
                        })
                    }
                    {renderRightClaret()}
                </div>
            </div>
        </div>
    )
}