import "./footer.scss"
import wildcard from "../../assets/wildcard-w.svg"

export const Footer = () => {

    return (
        <div className="footer-container">
            <div className="footer-inner-container">
                <img src={wildcard} alt="Wildcard Logo White" />
                <div className="footer-inner-container top">
                    <div className="left">
                        <div className="input-content">
                            <p>
                                SecureWildCard is a cybersecurity company that focuses on providing cybersecurity operation services to both private and public sector customers. Our team delivers top-notch security services like deployment and integration of cybersecurity tools, training and certification, as well as strategy and consulting services.
                            </p>
                        </div>
                    </div>
                    <div className="right">
                        <div className="top-links">
                            <ul className="company-footer-links">
                                <li> Contact Us </li>
                                <li> sales@securewildcard.com </li>
                            </ul>

                            <ul className="products-footer-links">
                                <li> Topics </li>
                                <li><a href="/about-us">About Us </a></li>
                                <li> <a href="/services">Services </a></li>
                                <li> <a href="/contact-us">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* <hr /> */}
                <div className="footer-inner-container bottom">
                    <p className="left-links">
                        © {new Date().getFullYear()} Secure Wildcard All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    )
}