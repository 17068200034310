
import "./singleinputlabel.scss"


const Singleinputlabel = (props) => {

  const handleChange = (e) => {
    props.onChange(e)
  }
 
  return (
    <div className="form-input">
      <label> {props.label} </label>
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: 100 + "%",
        }}
      >
        <input
          name={props.name}
          type={props.type}
          placeholder={props.placeholder}
          style={{
            width: props.width + "px",
            marginBottom: 10,
            // border: props.error ? "1px solid red" : "",
            backgroundColor: props.error ? "#FFEBEB" : "",
          }}
        //   onChange={props.onChange}
          disabled={props.disabled}
          onChange={handleChange}
        />
      </div>
      {/* <p style={{ color: "red", height: 5, width: 100 + "%", margin: 0+"px" }}>
        {props.error ? props.error : " "}
      </p> */}
    </div>
  );
};
export default Singleinputlabel;
