import "./collapsibleitem.scss"
import { useState } from "react"

export const CollapsibleItem = (props) => {
    const { data, open } = props
    const [active, setActive] = useState(open)

    const toggleBox = () => {
        setActive(!active)
    }

    return (
        <div className={"collapsible"}>
            <div className={!active ? "collapse-block toggle-collapse" : "collapse-block toggle-expand"} onClick={(e) => toggleBox()}>
                {data.title}
                <div className={"image-box"}></div>
            </div>
            <div className={!active ? "content hide" : "content"} >
                <ul>
                    {data.data.map((value, index) => {
                        return <li key={index}>{value}</li>
                    })}
                </ul>
            </div>
        </div>
    )
}