import "./extended-services.scss"
import logo from "../../assets/secured-logo.svg"
import { CardSection } from "../cardsection/cardsection"
import { secServiceData } from "../../interfaces/interfaces"
import { Investments, Offers } from "../../interfaces/interfaces"
import { CardLists } from "../cardlists/cardlists"

export const ExtendedService = () => {
    const data = secServiceData

    return (
        <section id={"managed-security"}>
            <div className="extended-container">
                <div className="extended-inner-container">
                    <div className="extended-inner-container top">
                        <p>Managed Security Service</p>
                        <p className={"description"}>
                            As a Managed Security Service Provider (MSSP), we offer outsourced management of Detection, Security tools & systems. We take over your existing security tools and provide you with 24x7x365 transparent managed security service that protects your business.
                        </p>
                    </div>
                    <CardSection data={data} service_title={"Cloud Services"}></CardSection>
                </div>
            </div>
            <div className={"block-container"}>
                <div className={"block-content"}>
                    <p>The Wildcard Managed Security Service offerings are developed to provide continuous monitoring and detection for enterprise infrastructure.
                        This is a vital component of the company’s security strategy. </p>
                </div>
            </div>
            <div className="extended-container">
                <div className="extended-inner-container">
                    <div className="extended-inner-container bottom">
                        <CardLists data={Investments} title={"WHAT YOU HAVE"}></CardLists>
                        <div className="lists-container">
                            <p className={"title"}>WHAT WE HAVE</p>
                            <div className="lists-inner-container">
                                <div className={"list-item-container center"}>
                                  
                                        <img src={logo}></img>
                                        <p>The Secured Wildcard System</p>

                                </div>
                            </div>
                        </div>
                        <CardLists data={Offers} title={"WHAT YOU GET"}></CardLists>
                    </div>
                </div>
            </div>
        </section>
    )
}