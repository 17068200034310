import Singleinputlabel from "../input/singleinputlabel"
import Textarea from "../input/textarea"
import { useState, useEffect } from 'react';
import "./contactbox.scss"
import { react } from "@babel/types"
import * as EmailValidator from 'email-validator';
import { emailUrl } from "../../constants";
import axios from 'axios';

export const Contactbox = () => {
    const [formData, setData] = useState({
        name: "", email: "", message: ""
    })

    const [status, setStatus] = useState(false)

    const [submitting, setSubmitting] = useState({ res: false, message: "" });

    useEffect(() => {
        validateInput()
    }, [formData])

    const submitForm = (e) => {
        e.preventDefault()
        sendEmail()
    }

    const validateInput = () => {
        if (formData.name.length > 0 && formData.message.length > 0 &&
            EmailValidator.validate(formData.email)
        ) {
            setStatus(true)
        } else {
            if (status === true) {
                setStatus(false)
            }
        }
    }

    const sendEmail = () => {
        const body = JSON.stringify({ senderEmail: formData.email, senderName: formData.name, message: formData.message })

        const requestOptions = {
            method: "POST",
            body
        };

        fetch(emailUrl, requestOptions)
            .then((response) => {
                displayResult("Thank You. We will reply your email as soon as possible.")
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const displayResult = (message) => {
        setSubmitting({ res: true, message: message });

        setTimeout(() => {
            setSubmitting({ res: false, message: "" });
        }, 5000)
    }

    const handleChange = (e) => {
        setData({ ...formData, [e.target.name]: e.target.value })
    }

    const renderButton = () => {
        if (status) {
            return <button onClick={submitForm}>Send Us A Message</button>
        } else {
            return <button className={"disabled"} disabled={true}>Send Us A Message</button>
        }
    }


    const renderForm = () => {
        if (submitting.res) {
            return <div className={"title-bar"}><p className={"title-success"}>{submitting.message}</p></div>
        } else {
            return <form>
                <Singleinputlabel
                    type="text"
                    placeholder="Enter Your Full Name"
                    label="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                />
                <Singleinputlabel
                    type="email"
                    placeholder="Enter Your Email Address"
                    label="Your Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                />
                <Textarea
                    type="text"
                    label="Your Message"
                    name="message"
                    placeholder="Your Message"
                    value="enter your message"
                    onChange={handleChange}
                />

                {renderButton()}

            </form>
        }
    }

    return renderForm()
}