import "./cardlistitem.scss"

export const CardListItem = (props) => {
    const { data } = props

    return (
        <div className={"list-item-container"}>
            <img src={data.image}></img>
            <p>{data.description}</p>
        </div>
    )
}