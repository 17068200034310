import "./errors.scss"
import Errorsvg from "../../assets/errors.svg"
import { Link } from 'react-router-dom';
export const Error = ()=> {


    return (
        <div className="error-404-container">
            <div className="error-404-inner">
                <div className="error-404-inner-main">
                    <img src={Errorsvg} alt="Error-404-svg"/>
                    <p>Oops</p>
                    <p>
                        The page you are currently looking for 
                        isn’t found :(, maybe it was  removed or renamed,
                        we advise you go back to the homepage.
                    </p>
                    <Link className="error-404-btn" to="/" style={{textDecoration:'none'}}> Back To Homepage </Link>
                    {/* <button className="error-404-btn" > Sign In </button> */}

                </div>

            </div>

        </div>
    )
}