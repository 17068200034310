import "./product.scss"
import { useState } from "react"

export const Product = () => {

    const [product_key, setProduct] = useState("lite")

    const services = [
        'Triaging and Alerting',
        'Log Monitoring',
        'Incident Determination',
        'Remediation Advice',
        'Threat Hunting',
        'Reporting',
        'Incident Investigation and Analysis',
        'Detection Engineering',
        'Detection Configuration',
        'Playbook Development',
        'Security Product Deployment'
    ]

    const data = {
        lite: {
            data: [
                0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1
            ],
            info: "The lite security operation service focuses on companies who have existing SIEM, EDR, IDS/IPS environments setup already and are looking into getting professional service in to detect, alert and respond to events covering both on-premise and cloud infrastructure."
        },
        medium: {
            data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1
            ],
            info: "The medium security service operation is an upgrade to the lite security operation. It offers creating, improving and managing detection use cases covering several platforms such as EDRs and SIEMs solutions. "
        },
        full: {
            data: [
                0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
            ],
            info: "The full model is a fully managed security operation which focuses on providing, deploying, managing and operating security products (mostly detection tools)."
        }
    }

    const animationStyle = {
        WebkitAnimationDuration: `1s`,
        WebkitAnimationIterationCount: "1",
        WebkitAnimationName: "slide-in"
    }

    const listData = (key) => {
        setProduct(key)
    }

    return (
        <div className="product-container">
            <div className="product-inner-container">
                <div className="product-inner-container top">
                    <p>Security Operation Levels</p>
                    <p> Learn how to strengthen your security posture in ways that count. Understand your current security baseline and build a solid foundation for security that scales. </p>
                </div>

                <div className="product-offer">
                    <div className="product-offer top">
                        <p onClick={() => listData("lite")} className={product_key === "lite" ? " active" : " disable"}> <i className="far fa-star" /> Lite</p>
                        <p onClick={() => listData("medium")} className={product_key === "medium" ? " active" : " disable"}> <i className="fas fa-star-half-alt" /> Medium</p>
                        <p onClick={() => listData("full")} className={product_key === "full" ? " active" : " disable"}> <i className="fas fa-star" /> Full</p>
                    </div>
                    <hr/>
                    
                </div>
                
                <div className="product-card-container">
                    <div className="left-card">
                        <div className="big-info-box">
                            <div className="title" key={`title-${product_key}`} style={animationStyle}>{product_key.toUpperCase()} Package</div>
                            <div className="description" key={`desc-${product_key}`} style={animationStyle}>{data[product_key]['info']}</div>
                        </div>
                        <div className="small-info-box">
                            <div className={"primary"}><i class="fas fa-check-circle"></i> You Get </div>
                            <hr/>
                            <div className={"secondary"}><i class="fas fa-ban"></i> You Don't </div>
                        </div>
                    </div>
                    <div className="right-card">
                        {
                            services.map((value, key) => {
                                return <div key={`${key}-${product_key}`} className={data[product_key]['data'][key] ? "info-container secondary" : "info-container primary"}>
                                    <div className="card"><div className="card-title" style={animationStyle}>{value}</div></div>
                                    <div className="circle-card-container" style={animationStyle}>
                                        <div className="circle-card">
                                            <i className={data[product_key]['data'][key] ? "fas fa-ban" : "fas fa-check-circle"} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}