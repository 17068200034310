import "./about.scss"
import about from "../../assets/About.svg"
import info from "../../assets/w-logo.svg"
import why from "../../assets/why.svg"
export const Aboutbanner = ()=> {

    return (
        <div className="aboutbanner-container">
            <div className="aboutbanner-inner-container">
                <Sectioon
                    image={info}
                    title="About us?"
                    subtitle="There is a global trend in the growth of IT-related security issues companies currently face. The number of attack surfaces and trends are on the increase globally.
                    Our expertise lies in providing timely detection for organization. We pride ourselves to be environmentally agnostic and proactive when it comes to security operations and services.
                    <br/>
                    We are focused on enabling and supporting organizations to achieve their security goals and perform business seamlessly. Our target group are companies and firms in the EMEA (Europe, Middle east and Africa) and APAC (Asia Pacific) region.
                    "
                />
                <img src={about} alt="about svg"/>
                <Sectioon
                    image={why}
                    title="Why us?"
                    subtitle="We are a team of IT security professionals that protects your business by monitoring, detecting, analyzing, and investigating cyber threats.
                    Networks, servers, computers, endpoint devices, operating systems, applications and databases are continuously examined for signs of a cyber security incident.
                    <br/>
                    The team provides security consultation, develop defensible architecture, analyzes feeds, establishes rules, identifies exceptions, enhances responses and always keeps abreast of emerging threats and vulnerabilities."
                />
            </div>
        </div>
    )
}

const Sectioon = (props)=> {

    return (
        <div className="about-section">
            <div className="about-section left">
                {props.image && <img src={props.image} alt=""/>}
                <p>
                    {props.title}<br/>
                    <strong>
                        Securewildcard
                    </strong>
                </p>
            </div>
            <p className="subtitle" dangerouslySetInnerHTML={{ __html: props.subtitle}}>
            </p>
        </div>
    )
}