import "./cardsection.scss"
import { Card } from "../card/card"


export const CardSection = (props) => {
    const {data} = props

    const renderSection = 
         data.map((value, key) => {
            return <Card key={key} title={value.title} description={value.description} url={value.url} />
        })
    

    return (
        <div className="card-section-container">
            
            <div className="card-section-inner-container">
                { renderSection }

            </div>

        </div>
    )
}