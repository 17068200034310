import service from "../assets/service-1.svg"
import service2 from "../assets/service-2.svg"
import settings from "../assets/settings.svg"
import application from "../assets/application.svg"
import other from "../assets/other.svg"
import infrastructure from "../assets/infrastructure.svg"
import cloud from "../assets/cloud.svg"
import monitoring from "../assets/endpointalert.svg"
import time from "../assets/time.svg"
import platform from "../assets/iresponse.svg"
import hunting from "../assets/hunting.svg"
import detect from "../assets/deeng.svg"
import siemman from "../assets/siemman.svg"
import resupport from "../assets/resupport.svg"
import endpoint from "../assets/endpoint.svg"

interface Services {
  url: string;
  title: string;
  description: string;
  image: string;
}

interface SecService {
  title: string;
  description: string;
  url: string;
}

interface listService {
  title: string;
  description: string;
  image: string;
}


export const serviceDescriptionData: Services[] = [
  {
    description: "Our managed security operations service offerings are developed to support your business goals by providing security operations support for your enterprise IT infrastructure. Our Managed Security Service is focused on timely triaging, investigation and escalating of security incidents. ",
    title: "Managed Security Service",
    url: "services/#managed-security",
    image: service2
  },
  {
    description: "Our security consultancy services aims to define excellent security measures for customers with minimal investment. We can help your business by performing extensive business requirements analysis that enable us to understand the security elements needed to enhance seamless running of day-to-day business operations.",
    title: "Security Consultancy Service",
    url: "services/#consultation-service",
    image: service
  }
]

export const secServiceData: SecService[] = [
  {
    title: "Managed EDR Service",
    description: "Secure wildcard experts work with a range of industry leading Endpoint Detection and Response (EDR) solutions to provide continous monitoring of your Endpoints. Rather than deal with irrelevant tons of alerts, we can help you get the best out of your EDR solution.",
    url: "/services/edr"
  },
  {
    title: "Managed SIEM Operation",
    description: "Whether you decide to aggregate your logs into a central event collection and management platform for threat hunting, threat detection and response purposes or you are sending your logs to a SIEM for logging, monitoring, auditing and compliance reasons, Secure Wildcard can assist you along the way in your daily operations. Our 24/7 security operations specialists can assist you with security event monitoring, compliance dashboards, incident response and many more.",
    url: "/services/siem"
  },
  {
    title: "Managed Cloud Security Operations",
    description: "Today, data is the most attractive resource to cyber attackers. Traditionally, organizations have mostly built their security around their network infrastructure. ",
    url: "/services/cloud"
  }
]

export const Investments: listService[] = [
  {
    title: "",
    description: "Cloud Infrastructure (Azure, AWS, Google G-Suite)",
    image: cloud
  },
  {
    title: "",
    description: "Onprem Infrastructure(EDR, Network, SIEM)",
    image: infrastructure
  },
  {
    title: "",
    description: "Application (Web Servers, Active Directory e.t.c)",
    image: application
  },
  {
    title: "",
    description: "Endpoint (Desktop, Laptop, Mobile)",
    image: endpoint
  },

  {
    title: "",
    description: "Others (Slack, Atlassian e.t.c)",
    image: other
  }
]

export const Offers: listService[] = [
  {
    title: "",
    description: "Endpoint Monitoring and Alerting",
    image: monitoring
  },
  {
    title: "",
    description: "Threat Hunting",
    image: hunting
  },
  {
    title: "",
    description: "Incident Response",
    image: platform
  },
  {
    title: "",
    description: "Detection Engineering",
    image: detect
  },
  {
    title: "",
    description: "SIEM Management",
    image: siemman
  }
  ,
  {
    title: "",
    description: " Remediation Supportand Advisory",
    image: resupport
  }
]