import "./banner.scss"
import banner1 from "../../assets/Secure-2.svg"

export const Banner = (props) => {
    const { title, banner, description } = props

    let secondImage = banner1;
    const downloadUrl = "https://securewildcardpub.s3.amazonaws.com/scbrochure-main.pdf"

    const renderDescription = () => {
        if (description) {
            return <p>{description}</p>
        }

        return <p>Means you choose your own security stack and we make sure it works <strong>EFFICIENTLY</strong> by helping you avoid the frustration.</p>
    }

    const brochureDownloader = () => {
        fetch(downloadUrl).then(response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.id = "downloader"
                alink.href = fileURL;
                alink.download = 'securewildcard_brochure.pdf';
                alink.click();
            })
        })
    }

    return (
        <div className="banner-container">
            <div className="banner-inner-container">
                <div className="banner-inner-container left">
                    <div className="left top">
                        <p>{title ? title : " We Offer You Improved Security"}</p>
                        {renderDescription()}
                    </div>
                    <button className="regular" onClick={brochureDownloader}>Download Brochure</button>
                </div>
                <div className="banner-inner-container right">
                    <img alt={"Home Banner"} src={banner ? banner : secondImage} />
                </div>
            </div>
        </div>
    )
}