import "./help.scss"
import { Singlehelp } from "./singlehelp"
import { heldData } from "../../constants"
export const Help = () => {
    const data = heldData
    return (
        <div className="help-container">
            <div className="help-inner-container">
                <div className="help-inner-container top">
                    <p>How We Protect Your Business</p>
                    <p>
                        Our SOC analysts protect your business through the following steps.
                    </p>

                </div>
                <div className="help-inner-container bottom">
                    {data.map((value, key) => {
                        return <Singlehelp key={key} title={value.title} image={value.image} description={value.description} />
                    })}
                </div>
            </div>
        </div>
    )
}