import "./siem.scss"
import { Siemoverview, SiemWhy, Siemcore } from "../../constants"
import data from "../../assets/data.svg"
import analytics from "../../assets/analytics.svg"
import response from "../../assets/response.svg"
import { Singlepage } from "../../components/services/singlepage"
export const Siem = () => {
    const images = [data, analytics, response]
   
    return (
        
        <Singlepage
            images ={images}
            title="Managed  SIEM Operations"
            subtext={`With Our managed SIEM Operations, our experts would take over the implementation and operation of the SIEM solution for you.
            Our team of leading industry SIEM engineers are proficient and able to support you to operationalize and get the best out of  various SIEM solutions.`}
            overview="Having a team of leading industry experts ensures that Secure wildcard 
            engineers are proficient and able to help their clients operationalize various 
            SIEM solutions such as ELK stack, Splunk, QRadar etc. Our team of experienced personnel can support you with the following :"
            soverview={Siemoverview}
            why={SiemWhy}
            core="SIEM Core Capabilities"
            cservice={Siemcore}
    />
    )
}