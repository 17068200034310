import "./edr.scss"
import { Edroverview, Edrworks } from "../../constants"
import { core } from "../../constants"
import visible from "../../assets/completev.svg"
import attack from "../../assets/attack.svg"
import cimage from "../../assets/edr_platform.svg"
import hunt from "../../assets/hunt.svg"
import time from "../../assets/time.svg"
import { Singlepage } from "../../components/services/singlepage"


export const Edr = () => {
    const images = [visible, hunt, time,attack]
    return (
        <Singlepage
            images ={images}
            title="Managed EDR Services"
            subtext="Secure wildcard experts work with a range of industry
            leading Endpoint Detection and Response (EDR) solutions
            to provide continous monitoring of your Endpoints. Rather than
            deal with tons of irrelevant alerts, we can help you get the best
            out of your EDR solution."
            overview="Secure wildcard is a cyber security managed service provider and our
            team of experienced personnel can support you with the following :"
            soverview={Edroverview}
            core="EDR Core Capabilities"
            works= {Edrworks}
            cservice={core}
            worksimage={cimage}
        />
    )
}