import "./services.scss"
import { Singleservice } from "./singleservice"


export const Service = (props) => {
    const { service_title, data } = props

    const renderSection = () => {
        return data.map((value, key) => {
            return <Singleservice reverse={key % 2 === 1} key={key} title={value.title} description={value.description} image={value.image} url={value.url} />
        })
    }

    return (
        <div className="services-container">
            <div className="services-inner-container">
                <p>{service_title}</p>
                { renderSection() }
            </div>
        </div>
    )
}