import "./consultation.scss"
import { Collapsible } from "../collapsible/collapsible"

export const ConsultationService = () => {

    const data = [
        {
            title: "Security Services Requirement Analysis",
            data: [
                'A 1-day or 2-day security requirement analysis workshop.',
                'Threat informed defense strategies.',
                'Assist the customer in understanding their baseline cyber-security needs and posture.',
                'Analysis of the current security posture.',
                'Definition and planning of requirements to attain cyber-security goals and aligning them with the needs of specific business sectors.',
                'Provision of support for compromise assessment of the customer’s IT infrastructure in the event of a breach.',
                'Modelling of the environment to identify, enumerate and prioritize potential threats from hypothetically, an attacker’s point of view.',
                'Analysis of customer’s cyber security requirements using standardised industry cyber-security frameworks.',
                'Threat modelling and Threat analysis.'
            ],
        },
        {
            title: "Security Architecture",
            data: [
                'Collaborations with the customer to design and build architectures that manage identified risks in their environment.',
                'Provision of guidance to reduce the likelihood of the exploitation of vulnerabilities or reduced impact in the event of a compromise.',
                'Guidance in selecting technologies which meets the requirements and goals in pursuance of a strong cyber-security posture.'
            ],
        },
        {
            title: "Audit and Compliance Support",
            data: [
                'Support the customer to fulfil their compliance requirements through gap assessments and detailed planning to close the gaps.',
                'Engage with the customer’s IT operations team and other necessary stakeholders to ensure that the compliance requirements are fulfilled.',
                'Provision of detailed documents such as cyber security incident response process, standard operating procedures, security use-cases etc.'
            ],
        }
    ]

    return (
        <section id={"consultation-service"}>
            <div className="consultation-container">
                <div className="consultation-inner-container">
                    <div className="consultation-inner-container top">
                        <p>Consultation Services</p>
                        <p>
                            We aim to define execellent security measures for customers with minimal investment. Our expertise is built from  industry experience by delivering security solutions tailored to entreprise need, 
                            Our consultancy services include one or more of the following 
                            {/* <br></br> We provide this by performing extensive business requirements analysis which enable us to understand the security elements needed to enhance seamless running of day-to-day operations. */}
                        </p>
                    </div>
                    <div className="consultation-inner-container bottom">
                        <Collapsible data={data}></Collapsible>
                    </div>
                </div>
            </div>
        </section>
    )
}