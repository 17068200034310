import "./singleservice.scss"
import { NavLink } from "react-router-dom";

export const Singleservice = (props) => {
    const {title, description, url, image} = props

    return (
        <div className={!props.reverse ? `single-service-container no-reverse` : `single-service-container reverse`}>
            <div className={!props.reverse ? `single-service-container left` : `single-service-container left left-reverse`}>
                <p>{title}</p>
                <p>
                    {description}
                </p>
                    <NavLink
                        to={url}
                        style={{ textDecoration: "none" }}
                    >
                        Read More
                    </NavLink>
            </div>

            <div className="single-service-container right">
                {image != null ? <img src={image} alt="Service"/> : ""}
            </div>

        </div>
    )
}