import "./singlepage.scss"
import checked from "../../assets/circled-check.svg"
import { Contactbox } from "../../components/contactbox/contactbox"

export const Singlepage = (props) => {

    const printWork = () => {
        if (props.works) {
            return <div className="overview">
                <div className="overview-top">
                    <p>How It Works</p>
                    <img className={"full-image"} src={props.worksimage}></img>
                </div>
                <ul>
                    {props.works.map((data, index) => (
                        <Singleoverview key={index}
                            title={data}
                        />
                    ))}
                </ul>
            </div>
        }
    }

    const printWhy = () => {
        if (props.why) {
            return <div className="overview">
                <div className="overview-top">
                    <p>Why Managed SIEM Operation?</p>
                </div>
                <ul>
                    {props.why.map((data, index) => (
                        <Singleoverview key={index}
                            title={data}
                        />
                    ))}
                </ul>
            </div>
        }
    }

    return (
        <div className="singlepage-container">
            <div className="singlepage-inner-container">
                <div className="singlepage-head">
                    <p>{props.title}</p>
                </div>
                <div className="singlepage-inner-container bottom">
                    <div className="bottom-left">
                        <div className="top">
                            <p>{props.title}</p>
                            <div className="subtext" dangerouslySetInnerHTML={{ __html: props.subtext }} />
                        </div>

                        <div className="overview">
                            <div className="overview-top">
                                <p>{props.approach ? props.approach : 'Service Overview'}</p>
                                <p>
                                    {props.overview}
                                </p>
                            </div>

                            <ul>
                                {props.soverview.map((data, index) => (
                                    <Singleoverview key={index}
                                        title={data}
                                    />
                                ))}
                            </ul>

                        </div>

                        {printWork()}

                        {printWhy()}

                        <div className="core">
                            <div className="core-top">
                                <p>{props.core}</p>
                            </div>
                            <div className="core-content">
                                {(props.cservice ?? []).map((data, index) => (
                                    <Core
                                        key={index}
                                        image={props.images[index]}
                                        title={data}
                                    />
                                ))}
                            </div>
                        </div>

                    </div>
                    <div className="bottom-right">
                        <Contactbox />
                    </div>
                </div>
            </div>
        </div>
    )
}

const Singleoverview = (props) => {
    const { title } = props

    return (
        <li>
            <img src={checked} alt="checked svg" />
            <p dangerouslySetInnerHTML={{ __html: title}}></p>
        </li>
    )
}

const Core = (props) => {
    const { title, image } = props

    return (
        <div className="single-core">
            <img src={image} alt="checked svg" />
            <p>{title}</p>
        </div>
    )
}