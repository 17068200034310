import "./contact.scss";
import {Singledetail} from "./singledetail";
import Singleinputlabel from "../../components/input/singleinputlabel";
import Textarea from "../../components/input/textarea";
import { Contactbox } from "../../components/contactbox/contactbox";
export const Contact =()=> {
    return (
        <div className="contact-container">
            <div className="contact-inner-container">
                <div className="contact-head">
                    <p>Get In Touch</p>
                </div>
                <div className="contact-details">
                    <Singledetail
                        detail="Clifton House, Fitzwilliam Street Lower, Dublin 2, DUBLIN, D02 XT91, Ireland"
                        icon="fas fa-map-marked-alt"
                    />
                    <Singledetail
                        detail="sales@securewildcard.com"
                        icon="fas fa-envelope-open-text"
                    />
                </div>
                <Contactbox></Contactbox>
            </div>
        </div>
    )
}