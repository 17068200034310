import "./singlehelp.scss"

export const Singlehelp = (props) => {
    const {title, image, description} = props

    return (
        <div className="singlehelp-container">
            <div className="singlehelp-inner-container">
                <img src={image} alt="Single"/>
                <div className="content">
                    <p>{title} </p>
                    <p>
                        {description}
                    </p>
                </div>
            </div>
        </div>
    )
}