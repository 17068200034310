import assess from "../src/assets/assess.svg"
import detect from "../src/assets/detect.svg"
import alert from "../src/assets/alert.svg"
import collect from "../src/assets/collect.svg"


export const Edroverview = [
    "24/7/365 alert monitoring, analysis, investigation, incident notification.",
    "EDR, SIEM management, optimization and fine-tuning.",
    "Develop custom rules for EDR/SIEM that can be tailored to specific threats applicable to your organisation.",
    "Provide remediation recommendation/advise if applicable.",
    "Review and respond to your cyber-security concerns/inquiries in a timely manner.",
    "Communicate with established communication and escalation points in the case of a suspected or actual cyber security incident.",
    "Direct communication with EDR vendor in the case of vendor specific related concerns and improvements.",
    "Document details of investigated alerts and escalated incidents in a centralized platform.",
    "Provide timely reporting of your security posture based on agreed KPIs and deliverables."
]

export const core = [
    "Complete visibilty",
    "Enhanced threat hunting.",
    "Reduced containment and incident response time.",
    "Attack Chain visualization."
]

export const Edrworks = [
    "Sensor based log collection",
    "Log transfer to a central server hosted On-prem or in the cloud",
    "Correlation with Vendor TI feeds, 3rd party TI, Yara and Secure Wildcard custom rules (Some configuration and finetuning phase)",
    "Events matches with Rules? Alert",
    "SW Triaging and Incident response team.",
]

export const Siemoverview = [
    "Consultation (choosing the best solution per customer)",
    "Deployment and system administration.",
    "Data Onboarding",
    "SIEM solution architecture.",
    "Development and Management of analytics/watchlist",
    "Daily triage of generated alerts"

]

export const SiemWhy = [
    "Reduced SIEM deployment time",
    "Access to expertise",
    "Informed decision making",
    "Flexibility",
    "Affordable"
]

export const Siemcore = [
    "Data collection",
    "Analytics.",
    "Adaptive Response"

]

export const Cloudoverview = [
    "<strong>Identity and Access</strong>: Improper identity and access management remains one of the biggest loopholes that adversaries exploit to compromise not just on-prem infrastructures but also Cloud infrastructures. Managing roles and privilege access can be complex and complicated, therefore it is necessary to monitor users and services access to resources in the cloud. At secure wildcard, we can take care of the alerts that are related to suspicious or unwanted access to your cloud resources, investigate alerts, identify threats and respond accordingly. ",
    "<strong>Data and application protection (Storage and SAAS)</strong>: All organizations rely on data to continue to function. Securing data at rest and in transit is very important to ensure that customer and organisation's intellectual property is protected. Cloud service providers provide different tools (for example Azure Identity protection or Amazon Macie etc) to ensure that any sort of data inconsistency or unauthorised access is monitored and reported. ",
    "<strong>Compute resources and endpoint security</strong>: There are several approaches to monitoring your endpoint. Traditionally, organisations rely on Anti-virus vendors and signature based detection to discover threats in their environment. However, recent attacks have shown that this is not enough as some threat vectors still manage to find their way in an organisation's cloud infrastructure. To increase the security of compute instances in the cloud, CSPs have built in-house EDR/XDR tools to continuously monitor threats on endpoints. There is also a possibility to use third party EDR products which are in existence due to the collaboration with CSPs. Feel free to contact us to discuss further these possibilities that enhances the protection of your cloud resources. ",
    "Managed Cloud Security posture."
]


export const emailUrl = "https://9iq6f9u9fi.execute-api.us-east-1.amazonaws.com/default/sendEmail"

export const heldData = [
    {
        image: assess,
        title: "Assess",
        description: "Secure Wildcard collaborates with you to assess your environment and your security needs. Our assessment is tailored first to your industry, followed by an analysis of your previous, current and future threats.Our aim is to provide the best sense of security according to your business needs. "
    },
    {
        image: collect,
        title: "Collect",
        description: "We collect logs for threat hunting, threat detection, correlation, alerting and incident response. The result of our assessment is a decision on the type of logs we need to collect to optimise our detection capabilities. During alert investigation, several log sources will be analyzed for incident determination and root cause analysis."
    },
    {
        image: detect,
        title: "Detect",
        description: "Our analysts do not only rely on vendor based alert rules such as EDR threat feeds, Azure sentinel detection rules, SIEM rules and AI analytics. Secure wildcard additionally provides bespoke detection rules and  enrichment to these systems to improve their detection capabilities. "
    },
    {
        image: alert,
        title: "Alert & Respond",
        description: "Our 24/7/365 security analysts are monitoring and triaging the alerts that are generated by your security tools stack. All alerts are investigated and we will only escalate incidents that matter to you. Secure Wildcard will additionally provide support and recommendations for remediating the situation if applicable."
    },
]