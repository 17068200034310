import "./card.scss"

export const Card = (props) => {
    const { title, description, url } = props

    return (
        <div className={"card-container"}>
            <div className="card-inner-container">
                <div className={"title"}>
                    <p>{title}</p>
                </div>
                <div className={"description"}>
                    <p>{description}</p>
                </div>
                <a href={url}>Read More</a>

            </div>
            
        </div>
    )
}