import "./tools.scss"
import office from "../../assets/office.svg"
import splunk from "../../assets/splunk.png"
import azure from "../../assets/azure.png"
import amazon from "../../assets/amazon.png"
import carbonblack from "../../assets/carbon-black.png"
import crowdstrike from "../../assets/crowdstrike.png"
import darktrace from "../../assets/darktrace.png"
import defender from "../../assets/defender.png"
import sentinel from "../../assets/senitnel.png"


export const Tools = ()=> {
    const images = [office, azure, amazon, sentinel, crowdstrike, defender, darktrace, carbonblack, splunk]

    return (
        <div className="tools-container">
            <div className="tools-inner-container">
                <p>Supported Tools</p>
                <p className="info">These are some of the tools we currently support.</p>
                <div className="brands">
                    {images.map((image, key) => {

                        return <img key={key} src={image} alt={` ${image} logo`}/>


                    })}
                </div>
            </div>
        </div>
    )
}