import "./cardlists.scss"
import { CardListItem } from "../cardlistitem/cardlistitem"

export const CardLists = (props) => {
    const { data, title } = props
    return (
        <div className="lists-container">
            <p className={"title"}>{title}</p>
            <div className="lists-inner-container">
                <div className="lists-inner-container content">
                    {
                        data.map((value, index) => {
                            return <CardListItem data={value} key={index}></CardListItem>
                        })
                    }
                </div>
            
            </div>
        </div>
    )
}