import "./navbar.scss"
import wildcard from "../../assets/wildcard.svg"
import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import { useState } from "react";
import azure from "../../assets/azure.svg"
export const Navbar = () => {
    const [chevron, setChevron] = useState(false)

    const [active_menu, setActive] = useState(0)

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    var updateChevron = (update) => {
        setChevron(update)
    }
    const [open, setOpen] = useState(false)

    const service_sublink = [
        {
            name: "Managed EDR Service",
            link: "/services/edr",
        },
        {
            name: "Managed SIEM Operation",
            link: "/services/siem",
        },
        {
            name: "Managed Cloud Security",
            link: "/services/cloud",
        }
    ]

    return (
        <div className="navbar-container">
            <div className="navbar-inner-container">
                <div className="navbar-inner-container left">
                    <img src={wildcard} alt="wildcard-logo" onClick={() => scrollToTop()} />
                </div>
                <div className="navbar-inner-container right">


                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        exact
                        to="/"
                        style={{ textDecoration: "none" }}
                        onClick={() => setActive(0)}
                    >
                        Home
                    </NavLink>

                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/about-us"
                        style={{ textDecoration: "none" }}
                        onClick={() => setActive(0)}
                    >
                        About Us
                    </NavLink>
                    <div className="services">
                        <NavLink
                            className="nav-link"
                            activeClassName="active"
                            to="/services"
                            style={{ textDecoration: "none" }}
                            onMouseEnter={() => updateChevron(true)}
                            onClick={() => setActive(0)}
                        >
                            Services
                            <i class={chevron ? "fas fa-chevron-down" : "fas fa-chevron-up"}></i>
                        </NavLink>

                        <div className={`box ${chevron ? " active" : " disable"} `} onMouseLeave={() => updateChevron(false)} >
                            <div className="box-inner">
                                {
                                    service_sublink.map((value, index) => {
                                        index = index + 1
                                        return <div key={index} className={`sub-menu ${active_menu == index ? " active" : " "} `} onClick={() => setActive(index)}>
                                            <NavLink className="nav-link" to={value.link} className="sub-mini" style={{ textDecoration: "none" }}>{value.name}</NavLink>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                    </div>

                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/contact-us"
                        style={{ textDecoration: "none" }}
                        onClick={() => setActive(0)}
                    >
                        Contact Us
                    </NavLink>

                </div>
                <p className="Menu-toggle" onClick={() => setOpen(!open)}><i className={` ${open ? 'fas fa-times fa-2x' : 'fas fa-bars fa-2x'}`}></i></p>
            </div>
            <div className={`menu-responsive-container ${open ? ' active' : ' disabled'}`}>
                <div className="menu-responsive-inner">
                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        exact
                        to="/"
                        style={{ textDecoration: "none" }}
                    >
                        Home
                    </NavLink>

                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/about-us"
                        style={{ textDecoration: "none" }}
                    >
                        About Us
                    </NavLink>
                    <div className="services">
                        <NavLink
                            className="nav-link"
                            activeClassName="active"
                            to="/services"
                            style={{ textDecoration: "none" }}
                            onMouseEnter={() => updateChevron(true)}
                            onClick={() => updateChevron(!chevron)}

                        >
                            Services
                            <i class={chevron ? "fas fa-chevron-down" : "fas fa-chevron-up"}></i>
                        </NavLink>

                        <div className={`box ${chevron ? " active" : " disable"} `} onMouseLeave={() => updateChevron(false)} >
                            <div className="box-inner">
                                {
                                    service_sublink.map((value, index) => {
                                        index = index + 1
                                        return <div key={index} className={`sub-menu ${active_menu == index ? " active" : " "} `} onClick={() => setActive(index)}>
                                            <NavLink className="nav-link" to={value.link} className="sub-mini" style={{ textDecoration: "none" }}>{value.name}</NavLink>
                                        </div>
                                    })
                                }
                            </div>
                        </div>

                    </div>
                    <NavLink
                        className="nav-link"
                        activeClassName="active"
                        to="/contact-us"
                        style={{ textDecoration: "none" }}
                    >
                        Contact Us
                    </NavLink>
                </div>
            </div>
        </div>
    )
}