import "./about.scss";
import { Aboutbanner } from "../../components/aboutus/about";
import globe from "../../assets/globe.svg"
import { Values } from "../../components/aboutus/values";
export const About = () => {
    return (
        <div className="about-container">
            <div className="about-inner-container">
                <div className="about-head">
                    <p>About Us</p>
                </div>
                <Aboutbanner />
                <hr />
                <div className={"block-container"}>
                    <div className={"block-content"}>
                        <img src={globe}></img>
                        <p>Our main focus is to protect your organization from cyber attacks by monitoring, detecting, analyzing, and investigating cyber threats before it affects your business.</p>
                    </div>
                </div>
                <Values />
            </div>
        </div>
    )
}