import "./singledetail.scss"

export const Singledetail = (props) => {

    return (
        <div className="singledetail-container">
            <div className="singledetail-inner">
                <p><i className={props.icon}/></p>
                <p>{props.detail}</p>
            </div>
        </div>
    )
}